<template>
  <b10-base>
    <b10-toolbar
      title="Asignado con fecha fijada"
      back
    />
    <b10-page-content>
      <v-sheet
        class="mx-3 mt-5"
      >
        <b10-date-picker
          v-model="ffijada"
          title="Fecha fijada"
          @change="changeFilter"
        />
      </v-sheet>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        left
        :icon="$vuetify.icons.values.prev"
        @click="clickSiguienteAnteriorDia(-1)"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.next"
        @click="clickSiguienteAnteriorDia(1)"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { get, sync } from 'vuex-pathify'
import Data from './ParteAsignadoFechaFijadaData'
import { MACCION } from '@/utils/consts'
import { addDays, currentDate, toDate } from '@/utils/date'
import { encodeBase64 } from '@/utils/router'

const pageStoreName = 'pagesParteAsignadoFechaFijada'

export default {
  // no es un *View pero -> viewPageMixin para utilizar b10-view-details
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    if (!this.beforeRouteLeaveBase(to, from, next)) {
      if (!this.rememberState) {
        this.ffijada = null
      }
      next()
    }
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    ffijada: sync(`${pageStoreName}/ffijada`),
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.ffijada) {
        this.ffijada = toDate(this.ffijada)
      } else {
        this.ffijada = currentDate()
      }
      await this.loadDetailTotals()
    },
    async loadDetailTotals () {
      this.initDetails()
      // partes
      const detailPartes = this.addDetail(
        'partes',
        'Partes de trabajo',
        `Fijados para el ${this.$options.filters.shortDate(this.ffijada)}`,
        'parte'
      )
      const [datasetPartes] = await Data.selectAsignadosCount(
        this, this.usuarioIdtecnico, this.ffijada
      )
      detailPartes.badge = datasetPartes.count || 0
      // acciones
      const [datasetAcciones] = await Data.selectAccionesCount(
        this, this.usuarioIdtecnico, this.ffijada
      )
      if (datasetAcciones.length > 0) {
        this.addDetailHeader('Acciones')
        for (const accion of datasetAcciones) {
          const detailAcciones = this.addDetail(
            'acciones',
            accion.descripcion,
            '',
            MACCION.getIcon(accion.clasificacion),
            false,
            accion
          )
          detailAcciones.badge = accion.cantidad
        }
      }
    },
    async changeFilter () {
      await this.loadPage()
    },
    clickDetail (data) {
      if (data.detail.name === 'partes') {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'partes__parte-asignados-list',
          query: {
            _filter: encodeBase64({
              ffijada: {
                value: this.ffijada,
                options: {
                  fixed: true,
                },
              },
            }),
          },
        })
      } else if (data.detail.name === 'acciones') {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'partes__accion-asignado-fecha-fijada-list',
          params: {
            clasificacion: data.detail.data.clasificacion,
            ffijada: this.ffijada,
          }
        })
      }
    },
    async clickSiguienteAnteriorDia (offset) {
      this.ffijada = addDays(this.ffijada, offset)
      await this.loadPage()
    },
  }
}
</script>
